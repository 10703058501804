body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  oveflow: hidden;
}

.questions {
  padding: 20px;
  height: 100vh;
  overflow-y: auto;

  :global {
    .adm-selector {
      margin-bottom: 10px;
    }
  }

  .filter {
    margin-bottom: 20px;

    button {
      margin-bottom: 10px;
    }
  }

  .total {
    padding: 12px 0;

    > span, > div {
      display: inline-block;
      margin-right: 12px;
      vertical-align: middle;
    }
  }

  .btn {
    margin-right: 12px;
  }

  .summaryWrap {
    margin-bottom: 12px;
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
    font-size: 1rem;
  }

  .pagination {
    padding: 12px 0;
    text-align: center;
    margin-bottom: 50px;

    button {
      margin: 0 8px;
      cursor: pointer;
    }

  }

  .errorNum {
    color: #f60;
    margin: 0 12px
  }

  .tipWrap {
    display: flex;

    .answer {
      flex: 1
    }
  }

  .tip {
    //width: 300px;
    text-align: right;
    line-height: 24px;
  }

  .time {
    background-color: #eee;
    padding: 0 12px;
    border-radius: 8px;
    color: #888;
  }
}

@primary-color: #1DA57A;