.btn {
  position: absolute;
  right: 1rem;
  cursor: pointer;
  opacity: .7;
  font-size: 1.8rem;
  line-height: 6rem;

  &:hover {
    opacity: 1;
  }
}

@primary-color: #1DA57A;