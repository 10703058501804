.exContent {
  line-height: 1.8;

  .strong {
    font-weight: 700;

  }

  .is_gpt {
    color: @primary-color
  }

  audio {
    margin: 10px 0;
    zoom: .7
  }

  input {
    width: 100px;
    outline: none;
    border: 1px solid #ddd;

    &[data-correct="0"] {
      border: 1px solid #f00
    }
  }

  img {
    width: auto;
    height: auto;
    max-height: 300px;
    max-width: 60%
  }

  .title {
    font-weight: 700;

    div {
      display: inline;
    }
  }

  .ccAnswer {
    div {
      display: inline;
    }
  }

  .top {
    vertical-align: top;
  }
}

@primary-color: #1DA57A;