.header {
  font-size: 1.8rem;
  text-align: center;
  line-height: 6rem;
  border-bottom: 1px solid #eee;
}

.first {
  text-align: center;
  font-size: 1.8rem;
  padding-top: 3rem;
}

.list {
  //margin: 1.6rem;
  background-color: #fff;
  padding-bottom: 20rem;
  font-size: 1.6rem;

  .item {
    padding: 1.5rem;
    line-height: 1.6;
    border-bottom: 1px solid #eee;
    position: relative;
    font-size: 1.8rem;
    padding-right: 12rem;

    .en, .icon, .cn {
      margin-left: 1rem;
    }

    .icon {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      position: relative;
      z-index: 10000;
    }

    .score {
      //float: right;
      //margin-right: 10rem;
      position: absolute;
      right: 11rem;
      top: 1.5rem;
    }

    .button {
      position: absolute;
      right: 1.5rem;
      top: 1rem;
      display: inline-block;
      vertical-align: middle;
      margin-left: 2rem;
    }
  }

  .footer {
    margin-top: 3rem;
    text-align: center;
    padding: 0 2rem;

    .summary {
      margin-top: 1rem;
    }

    .btn {
      max-width: 20rem;
      margin: 3rem auto;
    }
  }

  :global {
    .adm-swipe-action-content > div {
      pointer-events: all !important;
    }
  }

}

@primary-color: #1DA57A;