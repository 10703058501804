.summary {
  margin-bottom: 1rem;

  .filter {
    color: #ddd;

    span {
      margin: 0 8px;
      color: #555;
      position: relative;

      &.active {
        color: #000;

        &:after {
          position: absolute;
          left: 9px;
          bottom: -8px;
          content: ' ';
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #ddd;
        }
      }
    }
  }

  ul {
    display: flex;
    margin: 0;
    padding: 10px 0;

    li {
      flex: 4 1;
      list-style: none;
      text-align: center;
      margin: 0;
      padding: 0;

      .label {
        margin-bottom: 10px;
      }

      .num {
        font-size: 28px;
        font-weight: 400;
      }
    }
  }
  .tooltip{
    padding: .5rem .5rem;
  }
}

@primary-color: #1DA57A;