.list {
  //margin: 1.6rem;
  background-color: #fff;
  padding-bottom: 20rem;

  .item {
    padding: 1.5rem;
    line-height: 1.6;
    border-bottom: 1px solid #eee;
    position: relative;
    font-size: 1.8rem;

    .en, .icon, .cn {
      margin-left: 1rem;
    }

    .icon {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      position: relative;
      z-index: 10000;
    }

    .score {
      float: right;
      margin-right: 10rem;
    }

    .button {
      position: absolute;
      right: 1.5rem;
      top: 1rem;
      display: inline-block;
      vertical-align: middle;
      margin-left: 2rem;
    }
  }

  .footer {
    margin-top: 2rem;
    text-align: center;
    padding: 0 2rem;

    .summary {
      margin-top: 1rem;
    }
  }

  :global {
    .adm-swipe-action-content > div {
      pointer-events: all !important;
    }
  }

}

@primary-color: #1DA57A;