.header {
  height: 64px;
  line-height: 64px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  position: relative;

  .add {
    position: absolute;
    left: 10px;
    top: 0;
    color: #222;
    text-decoration: none;
    font-size: var(--adm-font-size-main);
    font-weight: 400;

    &:hover, &:active {
      text-decoration: none;
    }
  }

  .name {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: var(--adm-font-size-main);
    font-weight: 400;
    line-height: 48px;
    padding-top: 6px;

    :global {
      .adm-dropdown {
        background-color: transparent;
      }

      .adm-dropdown-item-title {
        padding: 0;
      }
    }
  }
}

.selectUser {
  padding: 8px 20px;

  li {
    line-height: 36px;
    text-align: center;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: 0
    }
  }
}

@primary-color: #1DA57A;