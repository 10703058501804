html,
body {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overscroll-behavior: contain;
  font-size: 62.5%;
  //background-color: #f6f7fb;
  -webkit-transition: none;
  color: #222
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }

}

@primary-color: #1DA57A;