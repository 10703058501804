.summary {
  margin-bottom: 1rem;

  .finishNum {
    font-weight: 500;
    font-size: 16px;
  }


  ul {
    margin: 0;
    padding: 10px 0;

    li {
      list-style: none;
      text-align: center;
      margin: 0;
      padding: 0;
      display: flex;
      font-size: 1.4rem;
      margin-bottom: 1.2rem;
      padding-left: .5rem;

      .status {
        span {
          display: inline-block;
          padding: 4px 8px;
          line-height: 16px;
          vertical-align: middle;
        }
      }

      &[status="0"] {
        border-left: 4px solid #C72A29;

        .status span {
          background-color: #C72A29;
          color: #fff
        }
      }

      &[status="2"] {
        border-left: 4px solid #F8D347;

        .status span {
          background-color: #F8D347;
          color: #fff
        }
      }

      &[status="1"] {
        border-left: 4px solid #5DBC4C;

        .status span {
          background-color: #5DBC4C;
          color: #fff
        }
      }

      div {
        text-align: left;
        line-height: 28px;
        //flex: 1
      }

      .label {
        //margin-bottom: 10px;
        flex: 1;
      }

      .num {
        font-weight: 400;
        width: 60px;
        padding-left: .5rem;

      }

      .status {
        width: 60px;
        text-align: right;

      }
    }
  }
}

@primary-color: #1DA57A;