.progress {
  margin-bottom: 1rem;
  font-size: 1.4rem;

  .tip {
    float: right
  }

  .card {
    margin-bottom: 1rem;
  }

  .item {
    .name {
      margin-bottom: .5rem;
    }
  }
}

@primary-color: #1DA57A;