.cal {
  min-height: 430px;
  margin-bottom: 20px;

  :global {
    .adm-calendar-cell {
      position: relative;
      z-index: 1;
    }

    .adm-calendar-cell-top {
      color: #000;
    }

    .adm-calendar-cell-bottom {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      z-index: -1;
    }
  }

  .status1, .status2, .status3 {
    content: ' ';
    position: absolute;
    left: 3px;
    right: 3px;
    height: 100%;
    border-radius: 5px;
  }

  .status1 {
    background-color: #C72A29;
  }

  .status2 {
    background-color: #F8D347;
  }

  .status3 {
    background-color: #5DBC4C;
  }
}

@primary-color: #1DA57A;