.destory {
  padding: 2rem;
  font-size: 1.8rem;

  h5 {
    font-size: 1.8rem;
  }
}


@primary-color: #1DA57A;