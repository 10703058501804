.answer {
  padding-top: 10px;
  font-weight: 700;

  &[data-correct="0"] {
    color: #f00
  }

  &[data-correct="1"] {
    color: @primary-color
  }

  span {
    &[data-correct="0"] {
      color: #f00
    }

    &[data-correct="1"] {
      color: @primary-color
    }
  }
}

@primary-color: #1DA57A;